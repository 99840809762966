import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Stack,
  Avatar,
  Dialog,
  CircularProgress,
  DialogActions,
  Container,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageRatio from "../../../assets/ImageRatio.svg";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import AWS from "aws-sdk";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import BusinessActivity from "./BusinessActivity";
import { BusinessRejectedDocs } from "../../../api/bussiness/businessApi";
import { Link } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Gst_Details from "./Gst_Details";
import AadharDetails from "./AadharDetails";
import { ErrorMsg } from "../../../components/ErrorMsg";
import ApiService from "../../../APIs/api";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { SuccessMsg } from "../../../components/SuccessMsg";

const BusinessReferenceNumber = () => {
  // token from redux
  const token = useSelector((state: any) => state.user.userLogin.token);
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [Status0, setStatus0] = useState(false);
  const [Status1, setStatus1] = useState(false);
  const [Status2, setStatus2] = useState(false);
  const [Status3, setStatus3] = useState(false);
  // const [allDocusAlreadyApproved, setAllDocusAlreadyApproved] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      BusinessDetailsDocsStatus.AdminGSTVerified === "Approved" &&
      BusinessDetailsDocsStatus.AdminAadhaarS1Verified === "Approved" &&
      BusinessDetailsDocsStatus.AdminAadhaarS2Verified === "Approved" &&
      BusinessDetailsDocsStatus.AdminPanVerified === "Approved"
    ) {
      setisButtonDisabledforFinalStatus(true);
    } else {
      setisButtonDisabledforFinalStatus(false);
    }
  }, []);
  const updateStatus = (key: any, value: any) => {
    setBusinessDetailsDocsStatus((prevStatus: any) => ({
      ...prevStatus,
      [key]: value,
    }));
  };

  const [isButtonDisabledforFinalStatus, setisButtonDisabledforFinalStatus] =
    useState(false);
  // business details data
  const [expandBusinessDetails, setexpandBusinessDetails] = useState(true);
  const [userBusinessDetails, setuserBusinessDetails] = useState<any>({});
  const [userContactDetails, setUserContactDetails] = useState<any>({});
  const [GstRemarksDialog, setGstRemarksDialog] = useState(false);
  const [businessDetailsFetchStatus, setBusinessDetailsFetchStatus] =
    useState(false);
  const [businessDetailsDocs, setbusinessDetailsDocs] = useState<any>({});
  const [BusinessDetailsDocsStatus, setBusinessDetailsDocsStatus] =
    useState<any>({});
  const [DocsDialog, setDocsDialog] = useState(false);
  // adhar representative details
  const [expandAadharDetails, setexpandAadharDetails] = useState(true);
  const [userAadharDetails, setuserAadharDetails] = useState<any>({});
  const [AadharDetailsDocs, setAadharDetailsDocs] = useState<any>({});
  const [AadharDetailsDocsStatus, setAadharDetailsDocsStatus] = useState<any>(
    {}
  );

  const [userAadharPhotoLink, setuserAadharPhotoLink] = useState("");
  const [UserAadharNumber, setUserAadharNumber] = useState("");

  const [showDocsImage, setShowDocsImage] = useState("");
  const [askForClarification, setaskForClarification] = useState("");
  const [finalStatusRejectBtnDisabled, setfinalStatusRejectBtnDisabled] =
    useState(false);
  const [finalStatusApprovedBtnDisabled, setfinalStatusApprovedBtnDisabled] =
    useState(false);

  const [expandAcitivityDetails, setexpandAcitivityDetails] = useState(true);
  const [rejectedBtnDisable, setrejectedBtnDisable] = useState(false);
  // to Navigate or get the data from other sibling components
  const location = useLocation();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleUserGstProfile = async () => {
    setexpandBusinessDetails(!expandBusinessDetails);
    if (expandBusinessDetails) {
      setBusinessDetailsFetchStatus(true);

      let params = {
        id: location.state ? location?.state?.user_id?._id : "",
      };
      await ApiService.setToken(token);
      const responseData: any = await ApiService.get(
        "/admin/userbusinessdetail",
        params
      );
      if (responseData.status === 200) {
        setuserBusinessDetails(responseData.data.result.restResult);
        setbusinessDetailsDocs(responseData.data.result.resultFiles);
        setUserContactDetails(responseData.data.result.contactDetails);
        setBusinessDetailsDocsStatus(responseData.data.result.status);
        updateStatus(
          responseData.data.result.status.AdminGSTVerified,
          "Approved"
        );
        updateStatus("AdminAadhaarS1Verified", "Approved");
        updateStatus("AdminAadhaarS2Verified", "Approved");
        updateStatus("AdminPanVerified", "Approved");
        setBusinessDetailsFetchStatus(false);
      } else {
        console.log(responseData);
      }
    }
  };
  const handleClose = () => {
    setbtndesabled(true);
    setGstRemarksDialog(false);
  };
  const handleDocsImage = (image: any) => {
    setShowDocsImage(image);
    setDocsDialog(!DocsDialog);
  };
  const [Remark, setRemark] = useState("");
  const [btndesabled, setbtndesabled] = useState(true);
  const handleOnchangeSetRemarks = (e: any) => {
    if (e.target.value !== "") {
      setRemark(e.target.value);
      setbtndesabled(false);
    } else {
      setbtndesabled(true);
    }
  };

  const openDialogForGst = (value: any) => {
    setaskForClarification(value);
    setGstRemarksDialog(true);
  };
  const [aadharLoading, setaadharLoading] = useState(false);
  const handleUserAdharProfile = async () => {
    setaadharLoading(true);
    setexpandAadharDetails(!expandAadharDetails);
    if (expandAadharDetails) {
      let params = {
        id: location.state ? location.state.user_id : "",
      };
      await ApiService.setToken(token);
      const responseData: any = await ApiService.get(
        "/admin/businessrepresentativedetail",
        params
      );
      if (responseData.status === 200) {
        setuserAadharDetails(responseData.data.result.restResult);
        setUserAadharNumber(
          responseData.data.result.resultAadharPhoto.aadharNumber
        );
        setuserAadharPhotoLink(
          responseData.data.result.resultAadharPhoto.aadharPhotoLink
        );
        setAadharDetailsDocs(responseData.data.result.resultFiles);
        setAadharDetailsDocsStatus(responseData.data.result.status);
        setaadharLoading(false);
      } else {
        setaadharLoading(false);
        console.log(responseData);
        ErrorMsg("Something Went Wrong, Please Try Again !");
      }
    }
    setaadharLoading(false);
  };

  const ApprovedDocs = async (filename: string) => {
    let flag;
    if (filename === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
    } else if (filename === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
    } else if (filename === "PANFile") {
      flag = "AdminPanVerified";
    } else {
      flag = "AdminGSTVerified";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        _flag: flag ? flag : "",
        status: "Approved",
        id: location.state ? location.state.user_id : "",
      };

      await ApiService.setToken(token);
      const responseData: any = await ApiService.post(
        "/admin/approveDocument",
        value
      );
      if (responseData.status === 200) {
        if (filename === "aadharFileUrl") {
          setStatus1(true);
        } else if (filename === "aadharBackUrl") {
          setStatus2(true);
        } else if (filename === "PANFile") {
          setStatus3(true);
        } else if (filename === "AdminGSTVerified") {
          setStatus0(true);
        }
      } else {
        console.log(responseData, "response Data");
      }
    }
  };
  const RejectDocs = () => {
    let flag;
    let docNameKey;
    if (askForClarification === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
      docNameKey = "Admin_AadhaarS1_Verification_Clarification";
      setStatus1(false);
    } else if (askForClarification === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
      docNameKey = "Admin_AadhaarS2_Verification_Clarification";
      setStatus2(false);
    } else if (askForClarification === "PANFile") {
      flag = "AdminPanVerified";
      docNameKey = "Admin_Pan_Verification_Clarification";
      setStatus3(false);
    } else {
      flag = "AdminGSTVerified";
      setStatus0(false);
      docNameKey = "Admin_GST_Verification_Clarification";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        filename: flag ? flag : "",
        status: "Rejected",
        id: location.state ? location.state.user_id : "",
        docNameKey: docNameKey,
        clarification: Remark,
      };
      BusinessRejectedDocs(value, token);
      setGstRemarksDialog(false);
    }
  };

  const handleOnClickFinalStatus = async (value: any) => {
    let body = {
      key: value,
      id: location.state ? location.state.user_id._id : "",
    };
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post("/admin/finalstatus", body);
    if (responseData.status === 200) {
      if (value === "Approved") {
        setfinalStatusApprovedBtnDisabled(true);
      } else {
        setfinalStatusRejectBtnDisabled(true);
      }
      navigate("/app/userlist");
    } else {
      if (value === "Approved") {
        setfinalStatusApprovedBtnDisabled(false);
      } else {
        setfinalStatusRejectBtnDisabled(false);
      }
    }
  };
  const handleProfileActivity = async (value: any) => {
    setexpandAcitivityDetails(false);
  };
  // const verifyApprovedBtn = () => {
  //   if (
  //     Status1 ||
  //     Status2 ||
  //     Status3 ||
  //     Status0 ||
  //     finalStatusApprovedBtnDisabled
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleOnClickRejectedStatus = async (value: any) => {
    setrejectedBtnDisable(true);
    let body = {
      key: value,
      id: location.state ? location.state.user_id._id : "",
    };
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post("/admin/finalstatus", body);
    if (responseData.status === 200) {
      SuccessMsg("KYC documents rejected successfully");
      setfinalStatusApprovedBtnDisabled(true);
    } else {
      setrejectedBtnDisable(false);
      ErrorMsg("Something Went wrong");
    }
  };

  const [dataArray, setDataArray] = useState([1, 2, 3]); // Example array
  const maxAllowedLength = 5;

  const isButtonDisabled = dataArray.length >= maxAllowedLength;

  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={{
        minHeight: "80vh",
        height: "100%",
        pb: 4,
      }}
    >
      <Stack>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"18px"}
          sx={{ ml: 8, mt: 4 }}
          fontWeight={700}
          color={"#000000"}
        >
          Request Reference Number #
          {location?.state !== undefined
            ? location?.state?.referenceNumber
            : ""}
        </Typography>
      </Stack>

      {/* Step 1 Boxes */}
      <Box boxShadow={0} mx={8} my={2}>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
              backgroundColor: "transparent",
            },
          }}
        >
          <AccordionSummary
            onClick={handleUserGstProfile}
            expandIcon={
              <>
                <ExpandMoreIcon />
              </>
            }
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              mx={4}
              mt={1}
              component={"h5"}
              fontWeight={700}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Businesses Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {businessDetailsFetchStatus ? (
              <Box display={"flex"} justifyContent={"center"}>
                <Stack spacing={2}>
                  <CircularProgress color="success" />
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"30px"}
                    color={"#556486"}
                  >
                    Please wait
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <Stack
                  mx={4}
                  mt={-6}
                  flexDirection={"row"}
                  justifyContent={"flex-end"} // Align items to the right
                >
                  <Item>
                    <Stack
                      justifyContent={"flex-end"} // Align items to the right
                      spacing={0.5} // Adjust spacing between items
                    >
                      <Typography
                        fontFamily={"Public Sans"}
                        fontSize={12}
                        display="flex"
                        alignItems="center"
                        color={"#212B36"}
                      >
                        <PhoneIcon sx={{ mr: 1, color: "green" }} />
                        Mobile: {userContactDetails?.mobile}
                      </Typography>
                      <Typography
                        fontFamily={"Public Sans"}
                        fontSize={12}
                        display="flex"
                        alignItems="center"
                        color={"#212B36"}
                      >
                        <MailOutlineIcon sx={{ mr: 1, color: "#3b82f680" }} />{" "}
                        Email: {userContactDetails?.email}
                      </Typography>
                    </Stack>
                  </Item>
                </Stack>

                <Grid
                  container
                  sx={{
                    mt: 1,
                    px: 2,
                    "& .MuiPaper-root": {
                      px: "12px",
                      py: 1,
                    },
                  }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Gst_Details
                    loading={businessDetailsFetchStatus}
                    gst_Details={userBusinessDetails}
                  />
                  <Grid
                    container
                    sx={{
                      py: 2,
                      "& .MuiPaper-root": {
                        // padding: "2px",
                        px: "12px",
                        py: 1,
                      },
                    }}
                  >
                    {/* Aadhar details */}
                    {Object.keys(businessDetailsDocs).map(
                      (value: any, index: any) => {
                        return (
                          <Grid
                            sx={{ px: "12px", py: "7px" }}
                            // xs={2}
                            // sm={4}
                            // md={4}
                            key={index}
                          >
                            <Item>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={11}
                                color={"#919EAB"}
                                mb={1}
                              >
                                Business Doucment {index + 1}
                              </Typography>
                              <img
                                src={businessDetailsDocs[value]}
                                onClick={() =>
                                  handleDocsImage(businessDetailsDocs[value])
                                }
                                alt=""
                                height={"89px"}
                                width={"150px"}
                              />

                              {BusinessDetailsDocsStatus &&
                              BusinessDetailsDocsStatus.AdminGSTVerified !==
                                "Approved" ? (
                                <Box>
                                  {!Status0 ? (
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        ApprovedDocs("AdminGSTVerified")
                                      }
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor:
                                          "rgba(0, 171, 85, 0.16)",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 171, 85, 0.16)",
                                        },
                                      }}
                                    >
                                      Approve
                                    </Button>
                                  ) : (
                                    <Button
                                      size="small"
                                      disabled={Status0}
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                        border: "1px solid green",
                                      }}
                                    >
                                      <TaskAltIcon
                                        fontSize="small"
                                        sx={{ px: 2 }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <Button
                                    size="small"
                                    disabled={true}
                                    sx={{
                                      mt: 1,
                                      textTransform: "none",
                                      px: 2,
                                      backgroundColor:
                                        "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "8px",
                                      fontWeight: 700,
                                      fontSize: "13px",
                                      lineHeight: "22px",
                                      color: "dark",
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(145, 158, 171, 0.24)",
                                      },
                                    }}
                                  >
                                    <Typography
                                      fontSize={14}
                                      fontFamily={"Public Sans"}
                                      fontWeight={500}
                                    >
                                      {" "}
                                      Already Approved
                                    </Typography>
                                  </Button>
                                </Box>
                              )}

                              <Box>
                                <Button
                                  size="small"
                                  sx={{
                                    mt: 2,
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    px: "9px",
                                    color: "black",
                                    fontWeight: 700,
                                    fontSize: "13px",
                                    lineHeight: "22px",
                                    border:
                                      "2px solid rgba(145, 158, 171, 0.32)",
                                  }}
                                  onClick={() => openDialogForGst(value)}
                                >
                                  Reject
                                </Button>
                              </Box>
                            </Item>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
                <Dialog open={DocsDialog} style={{ borderRadius: "10px" }}>
                  <Container sx={{ pt: 2 }}>
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      <img
                        src={showDocsImage !== "" ? showDocsImage : ImageRatio}
                        style={{
                          height: "400px",
                          width: "500px",
                        }}
                        alt=""
                      />
                    </Stack>
                  </Container>
                  <DialogActions
                    sx={{
                      justifyContent: "end",
                      textAlign: "end",
                      px: 4,
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{ px: 4 }}
                      style={{
                        fontFamily: "Public Sans",
                        color: "white",
                        width: "auto",
                        fontSize: "14px",
                        backgroundColor: "#303981",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: "700",
                      }}
                      onClick={() => setDocsDialog(false)}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </AccordionDetails>
          {/*  */}
          <Dialog onClose={handleClose} open={GstRemarksDialog}>
            <Box sx={{ mx: 4, my: 2 }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
              >
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"20px"}
                  component={"h4"}
                  sx={{ my: 2 }}
                  lineHeight={"36px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"rgba(51, 74, 103, 1)"}
                >
                  Are you sure ?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography color={"rgba(85, 100, 134, 1)"}>
                  * Please list the reason for rejection and re-upload
                </Typography>
                <TextField
                  label="Remarks"
                  size="small"
                  onChange={handleOnchangeSetRemarks}
                  type="text"
                  name="UserAadharNumber"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 40,
                      minWidth: "23vw",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />{" "}
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
                my={4}
              >
                <Button
                  size="small"
                  onClick={handleClose}
                  sx={{
                    mt: 4,
                    textTransform: "none",
                    backgroundColor: "rgba(85, 100, 134, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(85, 100, 134, 1)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={RejectDocs}
                  disabled={btndesabled}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    backgroundColor: "rgba(255, 86, 48, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",

                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255, 86, 48, 1)",
                    },
                  }}
                >
                  Reject
                </Button>
              </Stack>
            </Box>
          </Dialog>
        </Accordion>
      </Box>

      {/* Step 2 Boxes */}
      <Box boxShadow={0} mx={8} my={2}>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
            },
          }}
        >
          <AccordionSummary
            onClick={handleUserAdharProfile}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              mx={4}
              mt={1}
              component={"h5"}
              fontWeight={700}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Authorised Business Representative Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {aadharLoading ? (
              <>
                <Box display={"flex"} justifyContent={"center"}>
                  <Stack spacing={1.5}>
                    <CircularProgress color="success" />
                    <Typography
                      component="div"
                      fontFamily={"Public Sans"}
                      fontSize={13}
                      fontWeight={700}
                      lineHeight={"25px"}
                      color={"#556486"}
                    >
                      Please wait
                    </Typography>
                  </Stack>
                </Box>
              </>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>

                <>
                  <Box component="form" sx={{ px: 4 }}>
                    <Stack direction={"row"} sx={{ my: 1.5 }}>
                      <TextField
                        disabled
                        label="Your Aadhar Number"
                        value={UserAadharNumber}
                        size="small"
                        type="text"
                        name="UserAadharNumber"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            borderColor: "#919EAB",
                            fontWeight: "500",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 40,
                            minWidth: "23vw",
                          },
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: "14px",
                            Height: "20px",
                          },
                        }}
                      />{" "}
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ ml: "20px", mt: 1 }}
                      >
                        <>
                          <DoneIcon
                            style={{ fontSize: "15px", color: "#36B37E" }}
                          />{" "}
                          <Typography
                            fontWeight={600}
                            fontSize={11}
                            fontFamily={"Public Sans"}
                            color={"#36B37E"}
                          >
                            {" "}
                            Business Details Fetched SuccessFully
                          </Typography>
                        </>
                      </Stack>
                    </Stack>
                    <Box>
                      <Grid
                        display={"flex"}
                        direction={"row"}
                        sx={{ fontSize: "12px" }}
                      >
                        <Grid container>
                          <Typography
                            component="div"
                            fontFamily={"Public Sans"}
                            fontSize={19}
                            fontWeight={700}
                            lineHeight={"30px"}
                            color={"#212B36"}
                          >
                            <Grid
                              container
                              sx={{
                                mt: 1,
                                "& .MuiPaper-root": {
                                  px: "12px",
                                  py: 1,
                                },
                              }}
                              spacing={{ md: 1 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <AadharDetails aadhar_info={userAadharDetails} />
                              <Grid
                                container
                                sx={{
                                  py: 2,
                                }}
                              >
                                {Object.keys(AadharDetailsDocs).map(
                                  (value: any, index: any) => {
                                    return (
                                      <Grid
                                        style={{
                                          padding: "12px",
                                        }}
                                        // xs={2}
                                        // sm={4}
                                        // md={4}
                                        key={index}
                                      >
                                        <Item>
                                          <Typography
                                            fontFamily={"Public Sans"}
                                            fontSize={12}
                                            color={"#919EAB"}
                                            mb={1}
                                          >
                                            Business Doucment {index + 1}
                                          </Typography>
                                          <img
                                            src={
                                              AadharDetailsDocs[value] !== ""
                                                ? AadharDetailsDocs[value]
                                                : ImageRatio
                                            }
                                            onClick={() =>
                                              handleDocsImage(
                                                AadharDetailsDocs[value]
                                              )
                                            }
                                            alt=""
                                            height={"89px"}
                                            width={"150px"}
                                          />
                                          {index === 0 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS1Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status1 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {index === 1 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status2 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {index === 2 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status3 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "grey",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          <Box>
                                            <Button
                                              size="small"
                                              sx={{
                                                mt: 2,
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                px: "9px",
                                                color: "black",
                                                fontWeight: 700,
                                                fontSize: "13px",
                                                lineHeight: "22px",
                                                border:
                                                  "2px solid rgba(145, 158, 171, 0.32)",
                                              }}
                                              onClick={() =>
                                                openDialogForGst(value)
                                              }
                                            >
                                              Reject
                                            </Button>
                                          </Box>
                                        </Item>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                        <Grid mx={3}>
                          <Item>
                            <Avatar
                              alt=""
                              src={
                                userAadharPhotoLink
                                  ? userAadharPhotoLink
                                  : "/static/images/avatar/1.jpg"
                              }
                              sx={{ width: 150, height: 160 }}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/*  */}
      <Box
        boxShadow={0}
        mx={8}
        my={3}
        // sx={{ borderRadius: "50px", backgroundColor: "white" }}
      >
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
              backgroundColor: "transparent",
            },
          }}
          // sx={{
          //   backgroundColor: "transparent",
          //   border: "1px solid black dashed",
          //   boxShadow:
          //     "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
          //   borderRadius: "12px",
          //   "&.Mui-expanded": {
          //     boxShadow:
          //       "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
          //     borderRadius: "12px",
          //     backgroundColor: "transparent",
          //     border: "1px solid black dashed",
          //   },
          // }}
        >
          <AccordionSummary
            onClick={handleProfileActivity}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              component={"h5"}
              fontWeight={700}
              mx={4}
              py={1.5}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Activity
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandAcitivityDetails ? (
              <Box display={"flex"} justifyContent={"center"}>
                <Stack spacing={2}>
                  <CircularProgress color="success" />
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"30px"}
                    color={"#556486"}
                  >
                    Please wait
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <BusinessActivity />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ mx: 8 }}>
        <Grid container>
          <Grid item xs={10}>
            <Link to={"/app/userlist"} style={{ textDecoration: "none" }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"start"}
              >
                <ArrowLeftIcon sx={{ mt: 0.15 }} />
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={15}
                  fontWeight={700}
                  lineHeight={"26px"}
                  color={"Black"}
                >
                  Back
                </Typography>
              </Stack>{" "}
            </Link>
          </Grid>
          <Grid xs={2}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"end"}
              spacing={2}
            >
              {" "}
              <Button
                size="small"
                onClick={() => handleOnClickRejectedStatus("Rejected")}
                disabled={rejectedBtnDisable}
                sx={{
                  mt: 2,
                  textTransform: "none",
                  backgroundColor: !rejectedBtnDisable
                    ? "var(--error-main, #FF5630)"
                    : "rgba(145, 158, 171, 0.24)",

                  borderRadius: "8px",
                  display: "inline-flex",
                  padding: "11px 28px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  fontWeight: 700,
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: !rejectedBtnDisable
                      ? "var(--error-main, #FF5630)"
                      : "rgba(145, 158, 171, 0.24)",
                  },
                }}
              >
                Reject
              </Button>
              <Button
                size="small"
                // onClick={() => handleOnClickRejectedStatus("Rejected")}
                onClick={() => handleOnClickFinalStatus("Approved")}
                sx={{
                  mt: 2,
                  display: "inline-flex",
                  padding: "11px 22px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "none",
                  backgroundColor: !finalStatusApprovedBtnDisabled
                    ? "var(--success-main, #36B37E)"
                    : "rgba(145, 158, 171, 0.24)",
                  borderRadius: "8px",
                  fontWeight: 700,
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: !finalStatusApprovedBtnDisabled
                      ? "var(--success-main, #36B37E)"
                      : "rgba(145, 158, 171, 0.24)",
                  },
                }}
              >
                Approve
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BusinessReferenceNumber;

// const awsbucketImage = (valueImage: any) => {
//   AWS.config.update({
//     accessKeyId: "AKIAXMCKOYBF2KUWPN64",
//     secretAccessKey: "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",
//     region: "ap-south-1",
//   });

//   const s3 = new AWS.S3();
//   const imageUrl = valueImage;
//   const generatePresignedUrl = (bucketName: any, key: any) => {
//     const params = {
//       Bucket: "apassetbucket",
//       Key: "https://apassetbucket.s3.ap-south-1.amazonaws.com/4809957019.pdf",
//       Expires: 60, // URL expiration time in seconds (e.g., 1 minute)
//     };

//     return s3.getSignedUrl("getObjectd", params);
//   };

//   // Example usage
//   // const bucketName = "YOUR_S3_BUCKET_NAME";
//   // const objectKey = "YOUR_S3_OBJECT_KEY";

//   const presignedUrl = generatePresignedUrl(
//     "apassetbucket",
//     "https://apassetbucket.s3.ap-south-1.amazonaws.com/4809957019.pdf"
//   );
//   console.log("Presigned URL:jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", presignedUrl);
// };
